<template>
    <main>
        <HeaderTab :title="$t('import.titre')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                    <p>{{ $t('import.message') }}</p>
                    <b-button @click="startImportOharas" variant="primary">
                        {{ $t('import.importer') }}
                    </b-button>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Import from '@/mixins/Import.js'
import User from '@/mixins/User.js'

export default {
    name: 'Import',
    mixins: [Import, User],
    data: () => ({
    }),
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
            
        },
        async startImportOharas() {
            await this.importOharas()
            await this.delogUser()
            this.$store.commit('nav/closeFooter')
        }
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
    }
}
</script>