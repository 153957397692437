import Request from '@/assets/js/requests.js'
import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'

var ImportMixin = {
	data() {
		return {

		}
	},
	methods: {
		importOharas: async function() {
			const url = this.constructRoute(Constants.IMPORT_OHARAS_API, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ImportMixin::importOharas", url)
			.catch(error => {
				console.error("ImportMixin::importOharas => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		}
	}
}

export default ImportMixin
